import React, { useContext, useState } from 'react';
import MailIcon from '@material-ui/icons/Mail';
import LockIcon from '@material-ui/icons/Lock';

import Input from '../UI/Input';
import Button from '../UI/Button';
import useForm from '../../hooks/useForm';
import validate from '../../helpers/validate';
import firebase from '../../services/firebase';
import { DocumentsContext, DocumentsFoldersContext, FolderContext, LoaderContext, NotificationContext, SectionContext, SignatureContext, SignatureTemplatesContext, UserContext } from '../../context';
import { useHistory } from 'react-router';
import { fetch_email_user } from '../../services/firestore';

const SignIn = () => {
  const initialData = {
    email: {
      email: true,
      // value: 'test@jurisur.com'
      value: ''
    },
    password: {
      required: true,
      // value: 'Jurisur2020!'
      value: ''
    }
  }
  const { formData, errors, setErrors, changeHandler } = useForm(initialData, validate);
  const { setTemplates, setDocuments, setFetchDocuments, setActions } = useContext(DocumentsContext)
  const { fetchFolders } = useContext(FolderContext)
  const { 
    user,
    setUser,
    setPartner,
    // setCustomers,
    setAdmins,
    setVerifiedCustomers,
    setSiteConfigs,
    setSiteConfigsLoaded,
    setImmocloudConfig,
    setAuthenticationToken,
    setUserClaims,
    setUrlStore,
    setIsNotSQHorCAIpartner,
    setSignatureCredentials,
    showSignatures,
    setShowSignatures,
    siteConfigsLoaded,
    isNotSQHorCAIpartner,
    canUseSignatures,
    urlStore,
    getAgency,
    setSignInResult
  } = useContext(UserContext) 
  const { fetchSignatures } = useContext(SignatureContext)
  const { setLoading } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)
  const { fetchDocFolders } = useContext(DocumentsFoldersContext)
  const { fetchSections } = useContext(SectionContext)
  const { fetchSignatureTemplates } = useContext(SignatureTemplatesContext)
  const [statusFetched, setStatusFetched] = useState(true);
  const [authToken, setAuthToken] = useState(null)
  const history = useHistory()

  const submitHandler = async (e) => {
    e.preventDefault();
    
    const formErrors = validate(formData, true);
    setErrors(formErrors);
    
    if(Object.keys(formErrors).length === 0) {
      setLoading(true)
      try {
        const user = await firebase.auth().signInWithEmailAndPassword(formData.email.value, formData.password.value)
        // console.log('user', user)
        const signInResultObject = await fetch_email_user()
        // console.log('user data', signInResultObject)
        setSignInResult({...signInResultObject, user})
        history.push('/')
      } catch(err) {
        // console.log('sign in error', err)
        setNotification({ msg: err.message || err, type: 'danger' })
      }
      setLoading(false)
    }
  }

  return(
    <div className="auth">
      <div className="auth__box">
        {/* <div className="auth__box_top">
          <h1>Immo Docs</h1>
        </div> */}
        <div className="auth__box_main">
          <img src="/images/new-logo-dark.png" alt="Logo" width={290} height={50} className='mx-auto'/>
          <p>Identification</p>
          <form className="form" onSubmit={submitHandler}>
            <Input 
              name="email"
              value={formData.email.value}
              onChange={changeHandler}
              placeholder="Email"
              error={errors.email}
              iconEl={<MailIcon />}
              whiteBackground
              iconSmall
              sizeMedium
            />
            <Input 
              type="password"
              name="password"
              value={formData.password.value}
              onChange={changeHandler}
              placeholder="Mot de passe"
              error={errors.password}
              iconEl={<LockIcon />}
              whiteBackground
              iconSmall
              sizeMedium
            />
            <Button type="submit" text="Se connecter" primary fullWidth medium />
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
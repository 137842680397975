import moment from 'moment'
import { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { AR24_DATE_FORMAT, AR24_STATUS_LABELS } from '../constants'
import { NotificationContext, RegisteredMailContext } from '../context'
import RegistredMailLayout from '../layouts/RegisteredMailLayout'
import { sortArrayOfObjects } from '../utils'
import { Redo, Sort, Download } from '../assets/icons'
import { CustomTooltip } from '../components/ui_new'
import { Refresh } from '@mui/icons-material'

const labelForStatus = (status) => {
  switch (status) {
    case 'waiting':
      return 'Expédiée'
    case 'dp':
    case 'ev':
      return 'Livrée'
    case 'AR':
      return 'Acceptée'
    case 'refused':
      return 'Refusée'
    case 'negligence':
      return 'Négligence'
    default:
      return status
  }
}

const statusClassForStatus = (status) => {
  switch (status) {
    case 'waiting':
    case 'dp':
    case 'ev':
      return 'status--pending'
    case 'refused':
    case 'negligence':
      return 'status--error'
    case 'AR':
      return 'status--success'
    default:
      return 'status--pending'
  }
}

const formatDate = (date) => {
  const dateObj = moment(date, AR24_DATE_FORMAT)
  return dateObj.format('DD MMM YYYY')
}
const formatTime = (date) => {
  const dateObj = moment(date, AR24_DATE_FORMAT)
  return dateObj.format('HH:mm')
}

const RegisteredMailTracking = () => {

  const {
    ar24MailFilter,
    ar24Mail,
    ar24MailFetched,
    ar24Sender,
    showSenderModal,
    ar24SenderFetched
  } = useContext(RegisteredMailContext)

  const [filteredRegisteredMail, setFilteredRegisteredMail] = useState([])

  const [allRecipients, setAllRecipients] = useState({})

  const [activeSort, setActiveSort] = useState('meta.created')
  const [activeOrder, setActiveOrder] = useState('asc')

  const onSort = (sort) => {
    setActiveSort(sort)
    setActiveOrder(activeOrder === 'desc' ? 'asc' : 'desc')
  }

  useEffect(() => {
    let filtered = [...ar24Mail]
    if (ar24MailFilter.status && ar24MailFilter.status.length > 0) {
      filtered = filtered.filter(mail => ar24MailFilter.status.includes(mail.status))
    }
    if (ar24MailFilter.author) {
      if (ar24MailFilter.author !== 'all') {
        filtered = filtered.filter(mail => `${(mail.sent_by.admin_id)}` === `${ar24MailFilter.author}`)
      }
    }
    if (ar24MailFilter.agenceTracking) {
      if (ar24MailFilter.agenceTracking !== 'all') {
        filtered = filtered.filter(mail => `${(mail.sent_by.manufacturer_id || mail.sent_by.agency_id)}` === `${ar24MailFilter.agenceTracking}`)
      }
    }
    if (ar24MailFilter.dateAfter) {
      const afterTimestamp = ar24MailFilter.dateAfter.getTime()
      filtered = filtered.filter(mail => moment(mail.date, AR24_DATE_FORMAT).valueOf() >= afterTimestamp)
    }
    if (ar24MailFilter.dateBefore) {
      const beforeTimestamp = ar24MailFilter.dateBefore.getTime()
      filtered = filtered.filter(mail => moment(mail.date, AR24_DATE_FORMAT).valueOf() <= beforeTimestamp)
    }
    if (ar24MailFilter.search) {
      const search = ar24MailFilter.search.toLowerCase()
      filtered = filtered.filter(mail => {
        return mail.to_firstname?.toLowerCase().includes(search) ||
          mail.to_lastname?.toLowerCase().includes(search) ||
          mail.to_email?.toLowerCase().includes(search) ||
          mail.from_email?.toLowerCase().includes(search) ||
          mail.from_name?.toLowerCase().includes(search) ||
          mail.sent_by?.admin_name?.toLowerCase().includes(search)
      })
    }
    if (ar24MailFilter.date_after) {
      const afterTimestamp = ar24MailFilter.date_after.getTime()
      filtered = filtered.filter(mail => moment(mail.date, AR24_DATE_FORMAT).valueOf() >= afterTimestamp)
    }
    if (ar24MailFilter.date_before) {
      const beforeTimestamp = ar24MailFilter.date_before.getTime()
      filtered = filtered.filter(mail => moment(mail.date, AR24_DATE_FORMAT).valueOf() <= beforeTimestamp)
    }

    filtered = sortArrayOfObjects(filtered, activeSort, activeOrder === 'desc' ? 'asc' : 'desc')
    setFilteredRegisteredMail(filtered)
  }, [ar24Mail, ar24MailFilter, setFilteredRegisteredMail, activeSort, activeOrder])

  useEffect(() => {
    if (!ar24Mail) {
      return
    }
    let recipients = {}
    for (let mail of ar24Mail.filter(mail => mail?.sent_by?.agency_id)) {
      if (!recipients[mail.sent_by.agency_id]) {
        recipients[mail.sent_by.agency_id] = mail.sent_by.agency_name
      }
    }
    setAllRecipients({ ...recipients })
  }, [ar24Mail, setAllRecipients])

  const onCreateSender = () => {
    if (!ar24Sender) {
      showSenderModal('add')
    } else {
      showSenderModal('modify')
    }
  }

  const handlePreview = (mail, type) => {
    if (type === 'download') {
      window.open(mail.pdf_content, '_blank');
    } else if (type === 'preview') {
      window.open(previewUrl(mail), '_blank');
    } else if (type === 'full') {
      window.open(mail.zip, '_blank');
    }
  }

  const previewUrl = (mail) => {
    if (!mail) return
    if (mail[`${mail.status}_proof_url`]) {
      return mail[`${mail.status}_proof_url`]
    }
    const proofTypes = ['AR', 'ev']
    for (let type of proofTypes) {
      if (mail[`${type}_proof_url`]) {
        return mail[`${type}_proof_url`]
      }
    }
    return mail.proof_url
  }

  const mailSorter = (a, b) => {
    const aTimestamp = moment(a.date, AR24_DATE_FORMAT)
    const bTimestamp = moment(b.date, AR24_DATE_FORMAT)
    if (aTimestamp < bTimestamp) return 1
    if (aTimestamp > bTimestamp) return -1
    return 0
  }

  return (
    <RegistredMailLayout
      recipients={allRecipients}
      sidebarSection="tracking"
      onSort={onSort}
      activeSort={activeSort}
      activeOrder={activeOrder}
    >
      <div className="registered-mail-sent">
        <div className="registered-mail__box">
          {ar24SenderFetched && !ar24Sender && (
            <div className='mt-20 mb-40'>
              <p className='text-left'>
                {!ar24Sender ? 'Aucun compte expéditeur n\'a été ajouté.' : 'Le compte expéditeur n\'est pas vérifié ou est en attente de validation.'}</p>
              <button
                className='btn item-btn mt-20'
                onClick={onCreateSender}
              >
                {!ar24Sender ? 'Ajouter un compte AR24' : 'Accéder au compte'}
              </button>
            </div>
          )}
          {!ar24MailFetched &&
            <div className="loader-wrapper">
              <div className="loader-v2"></div>
            </div>}
          {ar24MailFetched && ar24Mail.length > 0 && <div className="registered-mail-table">
            <div className="registered-mail-table__head">
              <div className="registered-mail-table-row">
                <div className="registered-mail-table-column">
                  <p className={`${activeSort === 'status' ? 'active' : ''}`}>Statut de l’envoi</p>
                </div>
                <div className="registered-mail-table-column">
                  <p className={`${activeSort === 'to_firstname' ? 'active' : ''}`}>Destinataire</p>
                </div>
                <div className="registered-mail-table-column">
                  <p className={`${activeSort === 'to_email' ? 'active' : ''}`}>Email du destinataire</p>
                </div>
                <div className="registered-mail-table-column">
                  <p className={`${activeSort === 'date' ? 'active' : ''}`}>Date d'envoi</p>
                </div>
                <div className="registered-mail-table-column">
                  <p className={`${activeSort === 'from_email' ? 'active' : ''}`}>Compte expéditeur</p>
                </div>
                <div className="registered-mail-table-column">
                  <p className={`${activeSort === 'from_firstname' ? 'active' : ''}`}>Envoyé par</p>
                </div>
                <div className="registered-mail-table-column">
                  <p className={`${activeSort === 'price_ht' ? 'active' : ''}`}>Montant</p>
                </div>
                <div className="registered-mail-table-column">
                  <p>Preuves</p>
                </div>
              </div>
            </div>
            <div className="registered-mail-table__body">
              {filteredRegisteredMail.map((mail, idx) => (
                <RegisteredMailTableRow
                  mail={mail}
                  idx={idx}
                  onPreview={() => handlePreview(mail, 'preview')}
                  previewDisabled={!previewUrl(mail)}
                />
              ))}
            </div>
          </div>}
        </div>
      </div>
    </RegistredMailLayout>
  )
}

const RegisteredMailTableRow = ({ mail, idx, onPreview, previewDisabled }) => {

  const { refreshAr24MailStatus } = useContext(RegisteredMailContext)
  const { setNotification } = useContext(NotificationContext)

  const [refreshing, setRefreshing] = useState(false)

  // On status refresh click
  const handleStatusRefreshClick = async () => {
    setRefreshing(true)
    const refreshResponse = await refreshAr24MailStatus(mail.id)
    setRefreshing(false)
    if (refreshResponse.error) {
      // handle error
      setNotification({ msg: 'Erreur lors de la mise à jour du statut. ' + refreshResponse.error.message || '', type: 'danger' })
      console.error('Error refreshing status', refreshResponse.error)
    }
  }

  return (
    <div className={`registered-mail-table-row`} key={`registered_mail_table_row_${idx}`}>
      <div className="registered-mail-table-column">
        <CustomTooltip content={labelForStatus(mail.status)}>
          <p className='flex flex-row justify-start align-center gap-1'>
            <span className={`status ${statusClassForStatus(mail.status)}`}>
              <span className="text">
                {labelForStatus(mail.status)}
              </span>
            </span>
            <button className={`icon-btn icon-btn--transparent refresh-status ${refreshing ? 'refreshing' : ''}`} onClick={handleStatusRefreshClick}><Refresh fontSize='medium' /></button>
          </p>
        </CustomTooltip>
      </div>
      <div className="registered-mail-table-column">
        <CustomTooltip content={`${mail.to_firstname} ${mail.to_lastname}`}>
          <p className='text'>{mail.to_firstname} {mail.to_lastname}</p>
        </CustomTooltip>
      </div>
      <div className="registered-mail-table-column">
        <CustomTooltip content={mail.to_email}>
          <p className='text'>{mail.to_email}</p>
        </CustomTooltip>
      </div>
      <div className="registered-mail-table-column">
        <div className="line-clamp-2">
          <p >{formatDate(mail.date)}</p>
          <p className='time mt-1'>{formatTime(mail.date)}</p>
        </div>
      </div>
      <div className="registered-mail-table-column">
        <CustomTooltip content={mail.from_email}>
          <p className='text'>{mail.from_email}</p>
        </CustomTooltip>
      </div>
      <div className="registered-mail-table-column">
        <CustomTooltip content={mail.sent_by?.admin_name}>
          <p className='text'>{mail.sent_by?.admin_name}</p>
        </CustomTooltip>
      </div>
      <div className="registered-mail-table-column">
        <p className='text'>{mail.price_ht}€ HT</p>
      </div>
      <div className="registered-mail-table-column">
        <button className="button button--with-icon button--outline-primary-light rounded-sm download-btn" type='button' onClick={onPreview} disabled={previewDisabled}>
          <Download /> Télécharger
        </button>
      </div>
    </div>
  )
}

export default RegisteredMailTracking
import React, { useContext } from 'react';
import moment from 'moment';
import FolderIcon from '@material-ui/icons/Folder';

import Checkbox from './Checkbox';

import { areFolderActionsAuthorized, getAllParentFolders } from '../../utils';
import { FolderContext } from '../../context/folders/folderState';
import { DocumentsFoldersContext } from '../../context/documentsFolders/documentsFoldersState';
import { NotificationContext } from '../../context/notifications/notificationState';
import { LoaderContext } from '../../context/loader/loaderState';
import { DocumentsContext } from '../../context/documents/documentsState';
import { AdminFeatureWrapper } from '../utils/AdminFeatureWrapper';
import { UserContext } from '../../context';

const FolderRow = ({ selectedDocuments, doc, onCheckboxChange, onFolderClick, onMoveDocumentToFolder, onlyAdminEdit, view }) => {
  const { folders, updateFolder } = useContext(FolderContext);
  const { docFolders, updateDocFolder } = useContext(DocumentsFoldersContext);
  const { setNotification } = useContext(NotificationContext);
  const { setLoading } = useContext(LoaderContext);
  const { updateTemplate, currentFilter } = useContext(DocumentsContext);
  const { userClaims } = useContext(UserContext);

  // Drag start
  const dragStartHandler = (e) => {
    const div = document.createElement('div');
    div.id = 'draggable-folder-el';
    div.className = 'draggable-ghost-el';
    div.innerText = `Déplacer le ${doc.name}`;
    document.getElementById('root').appendChild(div);
    e.dataTransfer.setData('folder', JSON.stringify(doc));
    e.dataTransfer.setDragImage(div, 0, 0);
  }

  // Drag end
  const dragEndHandler = e => {
    document.getElementById('draggable-folder-el').remove();
  }

  // Drag over
  const dragOverHandler = (e) => {
    e.preventDefault();
    const box = e.target.closest('.folder-row');
    if(!box.classList.contains('hover')) {
      box.classList.add('hover');
    }
  } 
  
  // Drag Leave
  const dragLeaveHandler = (e) => {
    e.preventDefault();
    const box = e.target.closest('.folder-row');
    if(box.classList.contains('hover')) {
      box.classList.remove('hover');
    }
  } 
  
  // Drop
  const dropHandler = async (e, folder) => {
    const data = e.dataTransfer.getData('folder') ? JSON.parse(e.dataTransfer.getData('folder')) : null;

    const folderBox = e.target.closest('.folder-row');
    if(folderBox.classList.contains('hover')) {
      folderBox.classList.remove('hover');
    }
    
    if(data) {
      const parentFolders = getAllParentFolders(folder.docFolder ? docFolders : folders, folder);
      const folderBox = e.target.closest('.folder-row');
      if(folderBox.classList.contains('hover')) {
        folderBox.classList.remove('hover');
      }

      if(folder.id === data.id) 
        return setNotification({ msg: `Impossible de déplacer le dossier "${data.name}" vers le dossier "${folder.name}"`, type: 'warning' });
      if(folder.parentFolder === data.id) 
        return setNotification({ msg: `Impossible de déplacer le dossier "${data.name}" vers le dossier "${folder.name}"`, type: 'warning' });
      if(data.parentFolder === folder.id) 
        return setNotification({ msg: `Impossible de déplacer le dossier "${data.name}" vers le dossier "${folder.name}"`, type: 'warning' });
      if(parentFolders.find(f => f.id === data.id)) 
        return setNotification({ msg: `Impossible de déplacer le dossier "${data.name}" vers le dossier "${folder.name}"`, type: 'warning' });
  
      setLoading(true);
      // console.log(`Move ${data.name} to ${folder.name}`);
      if(folder.docFolder) {
        await updateDocFolder({ parentFolder: folder.id }, data.id);
      }else {
        await updateFolder({ parentFolder: folder.id }, data.id);
      }
      setLoading(false);
      setNotification({ msg: `Dossier "${data.name}" déplacé dans le dossier "${folder.name}"`, type: 'default' }); 
      return;
    }

    const templateData = e.dataTransfer.getData('template') ? JSON.parse(e.dataTransfer.getData('template')) : null;

    if(!areFolderActionsAuthorized(view, userClaims, folder)) {
      setNotification({ msg: 'Opération non autorisée', type: 'danger' });
      return;
    }

    if(templateData) {
      return moveTemplateToFolder(templateData, folder);
    }

    const documentData = e.dataTransfer.getData('document') ? JSON.parse(e.dataTransfer.getData('document')) : null;

    if(documentData) {
      onMoveDocumentToFolder(documentData, folder);
    }
  } 

  // Move template to folder
  const moveTemplateToFolder = async (template, folder) => {
    if(folder) {
      if(!template.folderId.includes(folder.id)) {
        const foldersArr = [];
        foldersArr.push(folder.id);
        await updateTemplate({ folderId: foldersArr }, template.id);
        setNotification({ msg: `Le modèle "${template.name}" a été déplacé dans le dossier "${folder.name}"!`, type: 'default' });
      }
      else {
        setNotification({ msg: `Le modèle "${template.name}" est déjà dans le dossier "${folder.name}"!`, type: 'warning' });
      }
      // if(template.folderId.length < 2 && !template.folderId.includes(folder.id)) {
      //   const foldersArr = [...template.folderId];
      //   foldersArr.push(folder.id);
      //   setLoading(true);
      //   await updateTemplate({ folderId: foldersArr }, template.id);
      //   setLoading(false);
      //   setNotification({ msg: `Le modèle "${template.name}" a été déplacé dans le dossier "${folder.name}" !`, type: 'default' });
      // }else if(template.folderId.length === 2 && !template.folderId.includes(folder.id)) {
      //   const foldersArr = [...template.folderId];
      //   foldersArr.splice(0,1);
      //   foldersArr.push(folder.id);
      //   setLoading(true);
      //   await updateTemplate({ folderId: foldersArr }, template.id);
      //   setLoading(false);
      //   setNotification({ msg: `Le modèle "${template.name}" a été déplacé dans le dossier "${folder.name}" !`, type: 'default' });
      // }else {
      //   setNotification({ msg: `Le modèle "${template.name}" est déjà dans le dossier "${folder.name}" !`, type: 'warning' });
      // }
    }
  }

  return currentFilter === 'all' ? (
    <div className={[...selectedDocuments].filter(obj => obj.id === doc.id).length > 0 ? "dashboard-row folder-row active" : "dashboard-row folder-row"}>
      <div className="dashboard-column col-1"
        draggable={true}
        onDragStart={dragStartHandler}
        onDragEnd={dragEndHandler}
        onDragOver={dragOverHandler}
        onDragLeave={dragLeaveHandler}
        onDrop={(e) => dropHandler(e, doc)}
      >
        { onlyAdminEdit ? ( <AdminFeatureWrapper rule="any_admin" altContent={(<span className="icon checkbox-placeholder"><FolderIcon /></span>)}>
          <Checkbox 
            onChange={onCheckboxChange} 
            checked={[...selectedDocuments].filter(obj => obj.id === doc.id).length > 0} 
          />
        </AdminFeatureWrapper> ) : (
          <Checkbox 
            onChange={onCheckboxChange} 
            checked={[...selectedDocuments].filter(obj => obj.id === doc.id).length > 0} 
          />
        ) }
        <span className="icon"><FolderIcon /></span>
        <div className="dashboard-column__main">
          <p className="title" onClick={() => onFolderClick(doc)}>{doc.name}</p>
        </div>
      </div>
      <div className={`dashboard-column col-2 col-font-small center date-col ${view === 'documents' ? 'doc-date-col' : ''}`} data-label="Créé le:">
        {view === 'documents' ? moment(doc.meta.created).format('DD MMM YYYY') : moment(doc.meta.created).format('DD MMM')} <span>{moment(doc.meta.created).format('HH:mm')}</span>
      </div>
      <div className={`dashboard-column col-3 col-font-small center date-col ${view === 'documents' ? 'doc-date-col' : ''}`} data-label="Modifié le:">
        {view === 'documents' ? moment(doc.meta.updated).format('DD MMM YYYY') : moment(doc.meta.updated).format('DD MMM')} <span>{moment(doc.meta.updated).format('HH:mm')}</span>  
      </div>
      {view === 'documents' && <div style={{width: 4}}></div>}
    </div>
  ) : null;
}

export default FolderRow;
import { SET_TEMPLATES, SET_DOCUMENTS, SET_FETCH_DOCUMENTS, SET_FILTERED_DOCUMENTS, SET_SHOW_FILTERED_DOCS, SET_CURRENT_FILTER, SET_ARCHIVED_DOCUMENTS, SET_DELETED_DOCUMENTS, SET_ACTIVE_FILTER_COLUMN, SET_DATE_FILTERS, SET_FILTERED_TEMPLATES, SET_TEMPLATES_LOADING, SET_ACTIVE_VARS_SECTION, SET_ACTIONS, SET_FETCH_ACTIONS, SET_SHOW_FILTERED_TMPLTS, SET_STANDARD_TEMPLATES, SET_STANDARD_TEMPLATES_LOADING, SET_GENERIC_TEMPLATES, SET_SPECIFIC_TEMPLATES } from '../types';

const documentsReducer = (state, action) => {
  switch(action.type) {
    case SET_TEMPLATES:
      return{
        ...state,
        templates: action.payload,
        templatesLoaded: true
      }
    case SET_DOCUMENTS:
      return{
        ...state,
        documents: action.payload,
        documentsLoaded: true
      }
    case SET_FETCH_DOCUMENTS:
      return{
        ...state,
        fetchDocuments: action.payload
      }
    case SET_ACTIONS:
      return{
        ...state,
        actions: action.payload,
        actionsFetched: true
      }
    case SET_FETCH_ACTIONS:
      return{
        ...state,
        fetchActions: action.payload
      }
    case SET_FILTERED_DOCUMENTS:
      return {
        ...state,
        filteredDocuments: action.payload,
        showFilteredDocs: true
      }
    case SET_ARCHIVED_DOCUMENTS:
      return {
        ...state,
        archivedDocuments: action.payload
      }
    case SET_DELETED_DOCUMENTS:
      return {
        ...state,
        deletedDocuments: action.payload
      }
    case SET_SHOW_FILTERED_DOCS: {
      return {
        ...state,
        showFilteredDocs: action.payload
      }
    }
    case SET_CURRENT_FILTER:
      return {
        ...state,
        currentFilter: action.payload
      }
    case SET_ACTIVE_FILTER_COLUMN:
      return {
        ...state,
        activeFilterColumn: action.payload
      }
    case SET_DATE_FILTERS:
      return {
        ...state,
        dateFilters: action.payload
      }
    case SET_FILTERED_TEMPLATES:
      return {
        ...state,
        filteredTemplates: action.payload,
        showFilteredTemplates: true
      }
    case SET_TEMPLATES_LOADING:
      return {
        ...state,
        templatesLoading: action.payload
      }
    case SET_STANDARD_TEMPLATES_LOADING:
      return {
        ...state,
        standardTemplatesLoading: action.payload
      }
    case SET_ACTIVE_VARS_SECTION:
      return {
        ...state,
        activeVarsSection: action.payload
      }
    case SET_SHOW_FILTERED_TMPLTS:
      return {
        ...state,
        showFilteredTemplates: action.payload
      }
    case SET_STANDARD_TEMPLATES:
      return {
        ...state,
        standardTemplates: action.payload,
        standardTemplatesLoaded: true
      }
    case SET_GENERIC_TEMPLATES:
      return {
        ...state,
        genericTemplates: action.payload
      }
    case SET_SPECIFIC_TEMPLATES:
      return {
        ...state,
        specificTemplates: action.payload
      }
    default:
      return state;
  }
}

export default documentsReducer;

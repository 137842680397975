import { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { DocumentsContext, FolderContext, UserContext } from '../../context'
import { FEATURE, timeDisplayFormat, dateOnlyDisplayFormat, isFeatureAuthorized, isFeatureEnabled, CLIENT_SPECIFIC_TEMPLATES_FOLDER_ID } from '../../utils'
import CustomTooltip from './CustomTooltip'
import { useSingleDocumentActions, useTemplatesActions } from '../../hooks'
import { Folder, Signature, CalendarAlt, History, Pen, Download, Categories, MoveToFolder } from '../../assets/icons';
import { AdminFeatureWrapper } from '../utils/AdminFeatureWrapper'
import ActionsDropdown from '../UI/ActionsDropdown'
import { canUseTemplateCategories, urlSuffixForEnvironment } from '../../utils'
import { AccountTree, DeleteForever, Edit, Publish, Share, Visibility, VisibilityOff, AttachFile, InfoOutlined, Add } from '@material-ui/icons'
import config from '../../config.json'
import SectionsPopup from '../sections/SectionsPopup'
import AttachmentsPopup from '../sections/AttachmentsPopup'
import SignaturesPopup from '../sections/SignaturesPopup'
import { Delete, MoreVert, OpenInNew } from '@mui/icons-material';
import { MoveSelectedItemsToFolderModal } from '../misc';

const TemplatesTableRow = ({ id, template, onPreview, onCreateDocument, standard, onTemplateEdit, onTemplateDelete, onTemplateToggleHidden, onInfo, onHistory, onManageAttachments }) => {
  const { partner, user, userClaims } = useContext(UserContext)
  const { moveTemplateToFolder } = useTemplatesActions()
  const { folders } = useContext(FolderContext)

  const [createdAt, setCreatedAt] = useState('')
  const [createdAtTime, setCreatedAtTime] = useState('')
  const [modifiedAt, setModifiedAt] = useState('')
  const [modifiedAtTime, setModifiedAtTime] = useState('')
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSectionsPopup, setShowSectionsPopup] = useState(false);
  const [showAttachmentsPopup, setShowAttachmentsPopup] = useState(false);
  const [showSignaturesPopup, setShowSignaturesPopup] = useState(false);
  const [showMoveToFolderModal, setShowMoveToFolderModal] = useState(false);
  const [hideDropdown, setHideDropdown] = useState(false);

  const sectionsPopupEl = useRef();
  const attachmentsPopupEl = useRef();
  const signaturesPopupEl = useRef();


  // Set metadata
  useEffect(() => {
    if (template?.meta?.created) {
      setCreatedAt(moment(template.meta.created).format(dateOnlyDisplayFormat))
      setCreatedAtTime(moment(template.meta.created).format(timeDisplayFormat))
    } else if (template.createdAt) {
      setCreatedAt(moment(template.createdAt).format(dateOnlyDisplayFormat))
      setCreatedAtTime(moment(template.createdAt).format(timeDisplayFormat))
    }
    if (template?.meta?.updated) {
      setModifiedAt(moment(template.meta.updated).format(dateOnlyDisplayFormat))
      setModifiedAtTime(moment(template.meta.updated).format(timeDisplayFormat))
    } else if (template.updatedAt) {
      setModifiedAt(moment(template.updatedAt).format(dateOnlyDisplayFormat))
      setModifiedAtTime(moment(template.updatedAt).format(timeDisplayFormat))
    }
  }, [template])


  // On preview modal open
  const handleOpenPreviewModal = () => {
    onPreview(template)
  }
  // On create document
  const handleCreateDocument = () => {
    onCreateDocument(template)
  }

  // On info modal open
  const handleOpenInfoModal = () => {
    // Workaround for hiding dropdown after opening information modal
    setHideDropdown(true);
    setTimeout(() => {
      setHideDropdown(false);
    }, 500);

    onInfo(template)
  }

  const handleOpenHistoryModal = () => {
    onHistory(template)
  }

  const shareTemplateClickHandler = (tmpl) => {
    window.open(`https://jurisur-document${urlSuffixForEnvironment(config.environment).replace('_', '-')}.web.app/document/${tmpl.id}?token=${user.clientAccessToken}`, '_blank');
  }

  const singleTemplateDownload = (tmpl) => {
    const a = document.createElement("a");
    a.setAttribute('download', tmpl.name);
    a.setAttribute('href', `${tmpl.templateUrl}`);
    document.body.appendChild(a);
    a.click();
  }

  // drag and drop template to folder
  const handleDragStart = (e) => {
    e.dataTransfer.setData('template', JSON.stringify(template));
    e.dataTransfer.setData('view', 'templates');
  };

  // On open move to folder
  const handleOpenMoveToFolderModal = (e, template) => {
    e.preventDefault()
    setShowMoveToFolderModal(true)
  }

  // On close move to folder
  const handleCloseMoveToFolderModal = () => {
    setShowMoveToFolderModal(false)
  }

  const handleTemplateMove = async (folder) => {
    await moveTemplateToFolder({ folder, template, view: "standard-templates" })
    handleCloseMoveToFolderModal()
  }

  const canMoveTemplateToFolder = (template, folder) => {
    if (folder?.id === CLIENT_SPECIFIC_TEMPLATES_FOLDER_ID) {
      return false
    }
    if (template.folderId.includes(CLIENT_SPECIFIC_TEMPLATES_FOLDER_ID)) {
      return false
    }
    return true
  }

  return (
    <div className={`documents-table-row !pl-0 no-cursor`}
      draggable={isFeatureAuthorized({ userClaims, rule: "any_admin" }) && canMoveTemplateToFolder(template)}
      onDragStart={handleDragStart}
      style={{ cursor: isFeatureAuthorized({ userClaims, rule: "any_admin" }) && canMoveTemplateToFolder(template) ? 'move' : 'default' }}
    >

      <div className="documents-table-column !flex-grow !pl-0">
        <div className="flex gap-2 px-3">
          <OpenInNew className='link-icon' onClick={handleCreateDocument} />
          <ActionsDropdown
            trigger={<button className="icon-btn icon-btn--transparent icon-btn--svg-xl more-vert-icon" onClick={() => setShowDropdown(!showDropdown)}><span className="svg-icon"><MoreVert fontSize='medium' /></span></button>}
            hideHeader
            width={120}
            dropdownClass="actions-dropdown--no-padding"
            hide={hideDropdown}
          >
            <ul className="dashboard-column__dropdown !border-b-0">
              {!standard && <li onClick={handleCreateDocument}><span className="icon add"><Add /></span>Créer un document</li>}
              {!standard && <li onClick={handleOpenInfoModal}><span className="icon rotate-180"><InfoOutlined /></span>Informations</li>}
              {!standard && <li onClick={handleOpenHistoryModal}><span className="icon rotate-180"><History /></span>Historique</li>}
              {isFeatureAuthorized({ userClaims, rule: "any_admin", resource: "templates" }) && <li onClick={() => onTemplateEdit(template)} className="edit"><span className="icon"><Pen /></span>Renommer</li>}
              {!standard && isFeatureAuthorized({ userClaims, rule: "any_admin", resource: "templates" }) && <li onClick={() => singleTemplateDownload(template)}><span className="icon"><Download /></span>Télécharger</li>}
              {!standard && isFeatureAuthorized({ userClaims, rule: "any_admin", resource: "templates" }) && canUseTemplateCategories(partner) &&
                <li>
                  <ActionsDropdown
                    trigger={<div className="flex gap-4"><span className="icon"><Categories /></span>Catégories</div>}
                    onTriggerClick={() => setShowSectionsPopup(true)}
                    hideHeader
                    dropdownClass="actions-dropdown--no-padding folders-popup-dropdown"
                    width={253}
                    onClose={() => setShowSectionsPopup(false)}
                    hide={!showSectionsPopup}
                    hideArrow={true}
                  >
                    <SectionsPopup
                      ref={sectionsPopupEl}
                      onCancel={() => setShowSectionsPopup(false)}
                      resource={template}
                    />
                  </ActionsDropdown>
                </li>}
              {!standard && isFeatureAuthorized({ userClaims, rule: "any_admin", resource: "templates" }) && <li onClick={() => onTemplateToggleHidden(template)}><span className="icon">{template.hidden ? <Visibility /> : <VisibilityOff />}</span>{template.hidden ? 'Rendre disponible' : 'Masquer'}</li>}
              {!standard && isFeatureAuthorized({ userClaims, rule: "any_admin", resource: "templates" }) && partner.includes('mls') && <li onClick={() => shareTemplateClickHandler(template)} className="edit"><span className="icon"><Share /></span>Partager</li>}

              {!standard && isFeatureAuthorized({ userClaims, rule: "any_admin", resource: "templates" }) && <li>
                <ActionsDropdown
                  trigger={<div className="flex gap-4"><span className="icon template-box"><Signature /></span>
                    <span>{'Paramètres signature électronique'}</span>
                  </div>}
                  onTriggerClick={() => setShowSignaturesPopup(true)}
                  hideHeader
                  dropdownClass="actions-dropdown--no-padding folders-popup-dropdown"
                  width={300}
                  onClose={() => setShowSignaturesPopup(false)}
                  hide={!showSignaturesPopup}
                  hideArrow={true}
                >
                  <SignaturesPopup
                    ref={signaturesPopupEl}
                    onCancel={() => setShowSignaturesPopup(false)}
                    resource={template}
                  />
                </ActionsDropdown>
              </li>}
              {(isFeatureAuthorized({ userClaims, rule: "any_admin" }) && isFeatureEnabled(FEATURE.DEFAULT_ATTACHMENTS)) &&
                <li onClick={() => onManageAttachments(template)}>
                  <div className="flex gap-4"><span className="icon"><AttachFile /></span>Annexes</div>                    
                </li>
              }
              {isFeatureAuthorized({ userClaims, rule: "any_admin" }) && canMoveTemplateToFolder(template) && <li onClick={(e) => handleOpenMoveToFolderModal(e, template)}><span className="icon"><MoveToFolder /></span>Déplacer</li>}
            </ul>
            {isFeatureAuthorized({ userClaims, rule: "any_admin", resource: "templates" }) && <ul className="dashboard-column__dropdown">
              <li className='dashboard-column__dropdown--trash' onClick={() => onTemplateDelete(template)}><span className="icon"><Delete /></span>Supprimer</li>
            </ul>}
          </ActionsDropdown>
        </div>
        <CustomTooltip content={template.name} position='top'>
          <p className='doc_name cursor-pointer line-clamp-2' onClick={handleCreateDocument}> &nbsp;{template.name}</p>
        </CustomTooltip>
      </div>
      <AdminFeatureWrapper rule="any_admin">
        <div className="documents-table-column documents-table-column__status">
          {!template.hidden && <span className='active-text'>Actif</span>}
          {template.hidden && <span className='hidden-text'>
            <VisibilityOff />
            Masqué</span>}
        </div>
      </AdminFeatureWrapper>
      <div className="documents-table-column documents-table-column__date">
        <div className="w-full">
          <p className='date'>{createdAt}</p>
          <p className='time'>{createdAtTime}</p>
        </div>
      </div>
      <div className="documents-table-column documents-table-column__date">
        <div className="w-full">
          <p className='date'>{modifiedAt}</p>
          <p className='time'>{modifiedAtTime}</p>
        </div>
      </div>
      <div className="documents-table-column documents-table-column__history">
        {(template.versionNotes && template.versionNotes.length > 0 && !standard) && <CustomTooltip content="Historique"><button className="icon-btn icon-btn--transparent icon-btn--svg-md mx-auto" onClick={handleOpenHistoryModal}><CalendarAlt /></button></CustomTooltip>}
      </div>

      {showMoveToFolderModal && (
        <MoveSelectedItemsToFolderModal
          onClose={handleCloseMoveToFolderModal}
          folders={folders}
          items={[template]}
          currentFolder={null}
          newFolderCreated={false} // remove this, improve
          onNewFolderCreated={() => { }} // remove this, improve
          onMove={handleTemplateMove}
        />
      )}
    </div>
  )
}

export default TemplatesTableRow